import * as Actions from '../actions'
import * as Constants from '../constants'
import * as Processes from '@rushplay/processes'
import * as R from 'ramda'
import * as Request from '../request'

import {bind} from 'redux-effects'
import {createPaymentMethodId} from '../create-provider-id'

function transformTransactionType(txType) {
  switch (txType) {
    case 'deposit': {
      return Constants.TransactionType.DEPOSIT
    }
    case 'withdraw': {
      return Constants.TransactionType.WITHDRAWAL
    }

    default: {
      throw new Error(`Unknown transaction type: ${txType}`)
    }
  }
}

function transformPaymentMethods(paymentMethods, txType) {
  return R.reduce(
    (methods, method) => {
      const transactionType = transformTransactionType(method.transaction_type)

      if (transactionType === txType) {
        const backendType = Constants.BackendType.VEVOPAY
        return R.append(
          R.map(
            (service) => ({
              accounts: [],
              backendType,
              canAddAccount: true,
              id: createPaymentMethodId({
                backendType,
                serviceType: service,
                providerType: method.provider,
                transactionType,
              }),
              limit: {
                max: `${method.limits.max_cents / 100}`,
                min: `${method.limits.min_cents / 100}`,
              },
              providerType: method.provider,
              transactionType,
            }),
            method?.services || []
          ),
          methods
        )[0]
      }
      return methods
    },
    [],
    paymentMethods
  )
}

export function fetchPaymentMethods(params) {
  const processId = `vevopay/fetch-payments-methods`
  return [
    Processes.start(processId),
    bind(
      Request.createRequest({
        backendType: Constants.BackendType.VEVOPAY,
        requestData: {
          countryCode: params.countryCode,
        },
        requestHeaders: {
          Accept: 'application/vnd.casinosaga.v1, application/json',
          Authorization: R.defaultTo('', params.sessionId),
        },
        requestMethod: 'GET',
        urlTemplate: '/vevopay/payment_methods{?countryCode}',
      }),
      (res) => [
        Actions.updatePaymentMethods(
          transformPaymentMethods(res.value, params.transactionType)
        ),
        Processes.stop(processId),
      ],
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          Actions.updatePaymentMethods(
            new Error('errors.vevopay.fetch-payment-methods-failure')
          ),
          Processes.stop(processId),
        ]
      }
    ),
  ]
}

/**
 * Start payment transaction
 *
 * @returns Redux Effects action
 */
export function performTransaction(params, transactionParams) {
  const processId = `perform-transaction-${params.paymentMethodId}`

  return [
    Processes.start(processId),
    bind(
      Request.createRequest({
        backendType: Constants.BackendType.VEVOPAY,
        bodyTemplate: {
          amount_cents: true,
          transaction_type: true,
          payment_method: true,
          bank_account_number: true,
        },
        requestData: {
          amount_cents:
            params.transactionType === 'withdrawal'
              ? `-${params.amountCents}`
              : params.amountCents,
          transaction_type: params.transactionType,
          payment_method: transactionParams.paymentMethod,
          bank_account_number: transactionParams.bankAccountNumber,
        },
        requestMethod: 'POST',
        requestHeaders: {
          Accept: 'application/vnd.casinosaga.v1, application/json',
          Authorization: params.sessionId || '',
        },
        urlTemplate: '/vevopay/payments',
      }),
      (res) => {
        const url = res.value?.iframe_bilgileri?.link
        return [
          Actions.startTransaction({
            redirectOutput: {
              container: 'vevopay',
              url: url,
              parameters: {},
            },
            transactionId: res.value?.transaction_id,
            paymentMethodId: params.paymentMethodId,
            transactionState: Constants.TransactionState.WAITING_INPUT,
          }),
          Processes.stop(processId),
        ]
      },
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          Actions.completeTransaction(
            new Error('errors.vevopay.perform-transaction-failure')
          ),
          Processes.stop(processId),
        ]
      }
    ),
  ]
}
